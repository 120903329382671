@charset "utf-8";

$footer-background-color: transparent;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

.page__hello--overlay
{
    @extend .page__hero--overlay;
    a {
        color: $link-color;
        text-decoration: none;
    }
    .wrapper {
        padding-left: 4.5em;
        padding-right: 4.5em;
    }
    .page__title {
        font-size: 4.5em;
        color: $text-color;
        text-shadow: none;
    }

    .page__lead {
        font-size: 1.75em;
        color: $text-color;
        text-shadow: none;
    }
}
